import { useActor } from "@xstate/react";
import * as React from "react";
import { useCallback, useContext, useState } from "react";
import { ActionButton } from "../../../form/ActionButton";
import { useDDProTranslations } from "../../../useDDProTranslations";
import { DDProStateContext } from "../../DDProStateProvider";

export const Toolbar: React.FunctionComponent = () => {
  const tt = useDDProTranslations();
  const pro = useContext(DDProStateContext);
  const [, send] = useActor(pro.machine!);
  const onClickOnAddMember = useCallback(() => send({ type: "ADD_MEMBER" }), [send]);
  const [text, setText] = useState("");
  const handleChange = useCallback(
    (e: any) => {
      const input = String(e.target.value).trim();
      setText(input);
      send({ type: "FILTER_CHANGE", input });
    },
    [send]
  );
  const handleClear = useCallback(() => {
    setText("");
    send({ type: "FILTER_CHANGE", input: "" });
  }, [send]);

  return (
    <div className="flex justify-center md:mb-4 md:w-2/3 w-full">
      <div className="relative w-full md:mr-2 py-1">
        <svg
          className="absolute top-4 left-3"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.3617 16.3459L12.9702 11.9465C13.974 10.7733 14.5595 9.22302 14.5595 7.58895C14.5595 3.81803 11.5064 0.801285 7.78407 0.801285C4.06175 0.801285 0.966797 3.81803 0.966797 7.54705C0.966797 11.2761 4.01993 14.3347 7.74224 14.3347C9.37337 14.3347 10.879 13.7481 12.0501 12.7844L16.4834 17.2258C16.6089 17.3515 16.7762 17.3934 16.9435 17.3934C17.1108 17.3934 17.2781 17.3515 17.4035 17.2258C17.6126 16.9744 17.6126 16.5973 17.3617 16.3459ZM7.78407 13.1196C4.73093 13.1196 2.26333 10.6476 2.26333 7.58895C2.26333 4.53031 4.68911 2.01636 7.78407 2.01636C10.879 2.01636 13.3048 4.48841 13.3048 7.54705C13.3048 10.6057 10.8372 13.1196 7.78407 13.1196Z"
            fill="#404040"
          />
        </svg>
        <svg
          onClick={handleClear}
          className={`${
            text === "" ? "opacity-0" : "opacity-100"
          } transition-all absolute right-2.5 top-3.5 cursor-pointer`}
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12.2676" cy="12.0642" r="12" fill="#C4C4C4" />
          <path
            d="M17.5176 16.2567L16.4601 17.3142L12.2676 13.1217L8.07508 17.3142L7.01758 16.2567L11.2101 12.0642L7.01758 7.87171L8.07508 6.81421L12.2676 11.0067L16.4601 6.81421L17.5176 7.87171L13.3251 12.0642L17.5176 16.2567Z"
            fill="white"
          />
        </svg>
        <input
          tabIndex={0}
          type="text"
          value={text}
          className="py-2 pr-4 px-10 bg-lighter-grey focus:bg-white rounded-full placeholder-gray-400 transition-all appearance-none inline-block w-full focus:shadow-lg focus:outline-none focus:ring-2 focus:ring-green"
          onChange={handleChange}
        />
      </div>
      <ActionButton className="hidden md:block min-w-max" label={tt.addMember} onClick={onClickOnAddMember} />
    </div>
  );
};
