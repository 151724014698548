import { useActor, useSelector } from "@xstate/react";
import * as React from "react";
import { HTMLAttributes, useCallback, useContext } from "react";
import { DDProStateContext } from "./DDProStateProvider";
import { ProMachineState, SubscriptionPayer } from "./types";

const groupTitleSelector = (state: ProMachineState) => state.context.groupTitle;
const isAdminPaysSelector = (s: ProMachineState) => s.context.subscriptionPayer === SubscriptionPayer.ADMIN;
const isMemberPaysSelector = (s: ProMachineState) => s.context.subscriptionPayer === SubscriptionPayer.MEMBER;
const isViewMembersScreenSelector = (s: ProMachineState) => s.matches({ members: "list" });

export function ClinicNameWithQR() {
  const pro = useContext(DDProStateContext);
  const [, send] = useActor(pro.machine!);
  const clinicName = useSelector(pro.machine!, groupTitleSelector);
  const onClickOnQR = useCallback(() => send({ type: "GO_TO_QR" }), [send]);
  const isAdminPays = useSelector(pro.machine!, isAdminPaysSelector);
  const isMemberPays = useSelector(pro.machine!, isMemberPaysSelector);
  const isViewMembersScreen = useSelector(pro.machine!, isViewMembersScreenSelector);

  return (
    <div className="-mt-6 md:mx-auto md:-mt-4 md:rounded flex items-center justify-between px-2 py-1 bg-green-100">
      <span className="w-8 invisible"></span>
      <div className="text-center text-sm py-1">{clinicName}</div>
      {isMemberPays && isViewMembersScreen && <QRIcon onClick={onClickOnQR} />}
      {(isAdminPays || !isViewMembersScreen) && <span className="w-6" />}
    </div>
  );
}

const QRIcon: React.FunctionComponent<HTMLAttributes<HTMLOrSVGElement>> = ({ className, onClick }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <path
      d="M3.24194 11.3511H11.2419V3.35109H3.24194V11.3511ZM5.24194 5.35109H9.24194V9.35109H5.24194V5.35109Z"
      fill="#404040"
    />
    <path
      d="M3.24194 21.3511H11.2419V13.3511H3.24194V21.3511ZM5.24194 15.3511H9.24194V19.3511H5.24194V15.3511Z"
      fill="#404040"
    />
    <path
      d="M13.2419 3.35109V11.3511H21.2419V3.35109H13.2419ZM19.2419 9.35109H15.2419V5.35109H19.2419V9.35109Z"
      fill="#404040"
    />
    <path d="M21.2419 19.3511H19.2419V21.3511H21.2419V19.3511Z" fill="#404040" />
    <path d="M15.2419 13.3511H13.2419V15.3511H15.2419V13.3511Z" fill="#404040" />
    <path d="M17.2419 15.3511H15.2419V17.3511H17.2419V15.3511Z" fill="#404040" />
    <path d="M15.2419 17.3511H13.2419V19.3511H15.2419V17.3511Z" fill="#404040" />
    <path d="M17.2419 19.3511H15.2419V21.3511H17.2419V19.3511Z" fill="#404040" />
    <path d="M19.2419 17.3511H17.2419V19.3511H19.2419V17.3511Z" fill="#404040" />
    <path d="M19.2419 13.3511H17.2419V15.3511H19.2419V13.3511Z" fill="#404040" />
    <path d="M21.2419 15.3511H19.2419V17.3511H21.2419V15.3511Z" fill="#404040" />
  </svg>
);
