import { useActor, useSelector } from "@xstate/react";
import * as React from "react";
import { useCallback, useContext, useMemo } from "react";
import { GetUsers_getDDProMembers } from "../../../../../types/GetUsers";
import { ActionButton } from "../../../form/ActionButton";
import { useDDProTranslations } from "../../../useDDProTranslations";
import { DDProStateContext } from "../../DDProStateProvider";
import { ProMachineState } from "../../types";
import { MemberItem } from "./MemberItem";
import { Toolbar } from "./Toolbar";

const numMembersSelector = (s: ProMachineState) => s.context.users.length;
const membersSelector = (s: ProMachineState) => s.context.users;
const filterSelector = (s: ProMachineState) => s.context.filter;
const isLoadingUsersSelector = (s: ProMachineState) => s.matches({ members: "loadingUsers" });

export function AllMembers() {
  const tt = useDDProTranslations();
  const pro = useContext(DDProStateContext);
  const [, send] = useActor(pro.machine!);
  const isLoadingUsers = useSelector(pro.machine!, isLoadingUsersSelector);
  const numOfMembers = useSelector(pro.machine!, numMembersSelector);
  const members = useSelector(pro.machine!, membersSelector);
  const filter = useSelector(pro.machine!, filterSelector);
  const onClickOnAddMember = useCallback(() => send({ type: "ADD_MEMBER" }), [send]);
  const membersFiltered = useMemo(
    () =>
      members.filter((item: GetUsers_getDDProMembers) => {
        const searchOptions = ["firstName", "lastName", "email"];
        return searchOptions.some((key: string) =>
          //@ts-ignore it complains on the item[key]
          item[key].toLowerCase().includes(filter.toLowerCase())
        );
      }),
    [members, filter]
  );
  const numOfMembersFiltered = useMemo(() => membersFiltered.length, [membersFiltered]);

  return (
    <div className="w-full flex flex-col px-4 md:px-0">
      <div className="flex flex-col justify-between mb-4 md:flex-row mt-8 md:px-3">
        <h1 className="font-normal text-4xl m-0 mb-4 md:mb-0 flex">
          {tt.members} <span className="font-normal text-lg">({numOfMembers})</span>
        </h1>
        <Toolbar />
      </div>
      {!isLoadingUsers && numOfMembersFiltered === 0 && <p className="text-center italic">{tt.noResults}</p>}
      {numOfMembersFiltered > 0 && (
        <>
          <MembersTableTitles />
          <ul className="divide-grey-100 divide-y flex flex-col list-none m-0 border-b border-grey-100 p-0">
            {membersFiltered.map((member, idx) => (
              <MemberItem key={idx} member={member} />
            ))}
          </ul>
        </>
      )}
      {isLoadingUsers && <p className="text-center italic">{tt.loading}</p>}
      <div className="md:hidden flex justify-center mt-4">
        <ActionButton
          className="bottom-4 fixed z-10 shadow-lg md:bottom-auto md:relative md:shadow-none"
          label={tt.addMember}
          onClick={onClickOnAddMember}
        />
      </div>
    </div>
  );
}

const MembersTableTitles = () => {
  const tt = useDDProTranslations();
  return (
    <div className="hidden md:grid font-medium grid-cols-12 gap-x-4 px-4 pb-2 items-center mt-8 border-b border-lighter-grey">
      <h3 className="hidden md:block md:col-span-5 m-0 text-base">{tt.name}</h3>
      <h4 className="hidden md:block md:col-span-5 m-0 text-base">{tt.email}</h4>
      <div className="col-span-8 md:hidden flex flex-col">
        <h3 className="m-0 text-base">{tt.name}</h3>
        <h4 className="m-0 text-base">{tt.email}</h4>
      </div>
      <span className="md:col-span-1 text-center">{tt.status}</span>
      <span className="w-2" />
      <span className="col-span-1 md:col-span-1"></span>
    </div>
  );
};
