import * as React from "react";
import { useCallback } from "react";

type ActionsType = Array<{ label: string; handler: () => void }>;

export const ActionMenu = ({ actions }: { actions: ActionsType }) => {
  const onClick = useCallback(
    (item: any) => (e: React.MouseEvent<HTMLSpanElement>) => {
      e.stopPropagation();
      item.handler();
    },
    []
  );
  return (
    <div className="absolute bg-white border border-lighter-grey right-3 rounded shadow-md top-6 w-48 z-10">
      <ul className="list-none m-2 p-0">
        {actions.map((item, idx: number) => (
          <li key={idx}>
            <span className="block text-sm p-2 cursor-pointer md:hover:bg-lighter-blue" onClick={onClick(item)}>
              {item.label}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};
