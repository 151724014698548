import { useSelector } from "@xstate/react";
import React, { useContext } from "react";
import Loading from "../../../../components/Loading/Loading";
import { useDDProTranslations } from "../../useDDProTranslations";
import { DDProStateContext } from "../DDProStateProvider";
import { ProMachineState } from "../types";
import { AllMembers } from "./allMembers/AllMembers";
import { RemoveConfirmationDialog } from "./ConfirmationDialogs";
import { NewMember } from "./NewMember";
import { ViewDetails } from "./ViewDetails";

const stateMatches = (obj: Object) => (s: ProMachineState) => s.matches(obj);

const loadMembersErrorSelector = stateMatches({ members: "loadUsersError" });
const loadingSelector = stateMatches({ members: "loading" });
const viewDetailsSelector = stateMatches({ members: "viewDetails" });
const removeUserDialogOpenedSelector = stateMatches({ members: "removeMember" });
const newMemberSelector = (s: ProMachineState) =>
  s.matches({ members: "newMember" }) || s.matches("add") || s.matches("addingMember");

export const Members: React.FunctionComponent = () => {
  const pro = useContext(DDProStateContext);

  const isLoading = useSelector(pro.machine!, loadingSelector);
  const isLoadMembersError = useSelector(pro.machine!, loadMembersErrorSelector);
  const isViewDetails = useSelector(pro.machine!, viewDetailsSelector);
  const isRemoveUserDialogOpened = useSelector(pro.machine!, removeUserDialogOpenedSelector);
  const isNewMember = useSelector(pro.machine!, newMemberSelector);

  if (isLoading) {
    return <Loading />;
  } else if (isLoadMembersError) {
    return <LoadMembersError />;
  } else if (isViewDetails) {
    return <ViewDetails />;
  } else if (isRemoveUserDialogOpened) {
    return (
      <>
        <AllMembers />
        <RemoveConfirmationDialog />
      </>
    );
  } else if (isNewMember) {
    return <NewMember />;
  } else {
    return <AllMembers />;
  }
};

//TODO: Move
const LoadMembersError: React.FunctionComponent = () => {
  const tt = useDDProTranslations();

  return (
    <div className="pt-16 sm:pt-32 px-6">
      <h1 className="text-4xl m-0">{tt.oopsWeCantFindYourMembers}</h1>
      <p>{tt.pleaseContactOurSupportIfYouEncounterThisIssueSeveralTimes}</p>
    </div>
  );
};
