import * as React from "react";
import { HTMLAttributes } from "react";

export const BackButton: React.FunctionComponent<HTMLAttributes<HTMLOrSVGElement>> = ({ onClick }) => (
  <div onClick={onClick} className="flex justify-center p-2 w-8 cursor-pointer md:hover:opacity-70">
    <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.97852 17L1.97852 9L9.97852 1"
        stroke="#404040"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);
