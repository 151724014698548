import { useActor, useSelector } from "@xstate/react";
import * as React from "react";
import { useCallback, useContext } from "react";
import { GetUsers_getDDProMembers } from "../../../../types/GetUsers";
import { ActionButton, ActionButtonAlt } from "../../form/ActionButton";
import { useDDProTranslations } from "../../useDDProTranslations";
import { DDProStateContext } from "../DDProStateProvider";
import { ProMachineState } from "../types";

const ConfirmationDialog = ({
  title,
  text,
  label,
  cancelEvent,
  confirmEvent,
}: {
  title: string;
  text?: string;
  label: string;
  cancelEvent?: () => void;
  confirmEvent?: () => void;
}) => {
  const tt = useDDProTranslations();
  return (
    <div className="bg-black bg-opacity-50 cursor-pointer fixed flex h-full left-0 p-6 top-0 w-full z-10">
      <div className="bg-white flex left-0 m-auto mx-auto rounded-xl shadow-lg">
        <div className="m-auto p-8">
          <h3 className="font-medium m-0 text-center">{title}</h3>
          <p className="text-center">{text}</p>
          <div className="flex flex-col sm:flex-row m-auto w-8/12 justify-evenly">
            {confirmEvent && <ActionButton onClick={confirmEvent} label={label} />}
            {cancelEvent && <ActionButtonAlt onClick={cancelEvent} label={tt.cancel} />}
          </div>
        </div>
      </div>
    </div>
  );
};

const currentMemberSelector = (s: ProMachineState) => s.context.currentUser as GetUsers_getDDProMembers;

export const RemoveConfirmationDialog = () => {
  const pro = useContext(DDProStateContext);
  const [, send] = useActor(pro.machine!);
  const member = useSelector(pro.machine!, currentMemberSelector);
  const removeUser = useCallback(() => send({ type: "REMOVE_MEMBER", member }), [send, member]);
  const handleCancel = useCallback(() => send({ type: "CLOSE" }), [send]);
  const tt = useDDProTranslations();

  return (
    <ConfirmationDialog
      title={tt.removeMember}
      text={
        member?.email ? tt.areYouSureYouWantToRemoveEmailHere(member?.email) : tt.areYouSureYouWantToRemoveIt
      }
      label={tt.remove}
      cancelEvent={handleCancel}
      confirmEvent={removeUser}
    />
  );
};
