import toast from "react-hot-toast";
import { assign, createMachine, DoneInvokeEvent, MachineConfig } from "xstate";
import AddUser from "../../../graphql/DDPro/AddUser.graphql";
import RemoveUser from "../../../graphql/DDPro/DeleteUser.graphql";
import GetGroups from "../../../graphql/DDPro/GetGroups.graphql";
import GetUsers from "../../../graphql/DDPro/GetUsers.graphql";
import { DDProPage, RegisterForDDProPage, HomePage } from "../../../pages";
import { GetUsers_getDDProMembers } from "../../../types/GetUsers";
import { client } from "../../../utils/apollo-client";
import { AddNewMemberFormValues } from "./states/NewMember";
import {
  AddUserPayload,
  FilterChangeEvent,
  GroupIdPayload,
  MachineOptions,
  ProMachineContext,
  ProMachineEvents,
  SubmitEvent,
  SubscriptionPayer,
  ViewMemberDetailsEvent,
} from "./types";

const machineConfig: (state?: string) => MachineConfig<ProMachineContext, any, ProMachineEvents> = (
  state = "members"
) => ({
  initial: "loading",
  id: "main",
  context: {
    users: [],
    currentUser: undefined,
    groupID: "",
    groupTitle: "",
    subscriptionPayer: SubscriptionPayer.UNKNOWN_PAYER,
    filter: "",
  },
  on: {
    GO_TO_INFO: "#main.info",
    GO_TO_LIST: "#main.members",
  },
  states: {
    info: {},
    loading: {
      invoke: {
        src: "loadGroup",
        onDone: [
          {
            target: state,
            actions: ["saveGroup"],
            cond: "isDDProAdmin",
          },
          { actions: ["redirectToPro"] },
        ],
        onError: { target: "loadMembersError" },
      },
    },
    loadMembersError: {},
    qr: {
      on: {
        CLOSE: {
          target: "#main.members",
        },
      },
    },
    add: {
      on: {
        CANCEL: { target: "members", actions: ["pushListToHistory"] },
        SUBMIT: { actions: ["setCurrentUser"], target: "addingMember" },
      },
    },
    addingMember: {
      initial: "adding",
      states: {
        adding: {
          entry: ["notifyAddingMember"],
          invoke: {
            src: "addUser",
            onError: [
              {
                actions: ["notifyAddExistingMemberError"],
                cond: "isErrorAddingAnExistingUser",
                target: "#main.add",
              },
              {
                actions: ["notifyAddMemberError"],
                target: "#main.add",
              },
            ],
            onDone: {
              actions: ["notifyAddMemberSuccess", "pushListToHistory"],
              target: "#main.members",
            },
          },
        },
      },
    },
    members: {
      initial: "loadingUsers",
      states: {
        loadMembersError: {},
        loadingUsers: {
          invoke: {
            src: "loadUsers",
            onDone: { actions: ["updateUsers"], target: "list" },
            onError: "loadMembersError",
          },
        },
        list: {
          on: {
            GO_TO_QR: "#main.qr",
            ADD_MEMBER: { target: "#main.add" },
            REMOVE_MEMBER: {
              actions: ["setCurrentUser"],
              target: "removeMember",
            },
            VIEW_MEMBER_DETAILS: {
              actions: ["setCurrentUser"],
              target: "viewDetails",
            },
            FILTER_CHANGE: {
              actions: ["filterChange"],
            },
          },
        },
        viewDetails: {
          on: {
            CLOSE: "list",
          },
        },
        removeMember: {
          on: {
            REMOVE_MEMBER: "removingUser",
            CLOSE: "list",
          },
        },
        removingUser: {
          entry: ["notifyRemovingCurrentUser"],
          invoke: {
            src: "removeUser",
            onDone: {
              actions: ["notifyCurrentUserRemoved"],
              target: "loadingUsers",
            },
            onError: {
              actions: ["notifyRemoveError"],
              target: "list",
            },
          },
        },
      },
    },
  },
});

export const machine = (options: MachineOptions) => {
  return createMachine<ProMachineContext, ProMachineEvents>(machineConfig(options.firstState), {
    actions: {
      pushListToHistory: () => options.navigate({ to: DDProPage }),
      redirectToPro: () => {
        options.navigate({ to: RegisterForDDProPage });
      },
      updateUsers: assign({
        users: (_, event) => (event as DoneInvokeEvent<any>).data.getDDProMembers,
      }),
      setCurrentUser: assign({
        currentUser: (_, event) => (event as ViewMemberDetailsEvent).member,
      }),
      navigateToHome: () => options.navigate({ to: HomePage }),
      saveGroup: assign((_, event) => {
        const { data } = event as DoneInvokeEvent<GroupIdPayload>;
        const firstGroup = data.getDDProGroups[0];
        const { id, title, subscriptionPayer } = firstGroup;

        return {
          groupID: id,
          groupTitle: title,
          subscriptionPayer,
        };
      }),
      filterChange: assign({ filter: (_, event) => (event as FilterChangeEvent).input }),
      notifyAddingMember: assign({
        addingMemberNotification: (_) => toast.loading(options.tt.adding), // eslint-disable-line @typescript-eslint/no-unused-vars
      }),
      notifyAddMemberSuccess: (ctx) =>
        toast?.success(`${options.tt.xHasBeenAdded(ctx.currentUser?.email)}`, {
          id: ctx.addingMemberNotification,
        }),
      notifyAddMemberError: (ctx, event) =>
        toast.error(
          `${options.tt.oopsCantPerformThisActionRightNow} | ${(event as DoneInvokeEvent<string>).data}`,
          {
            id: ctx.addingMemberNotification,
            duration: 10000,
          }
        ),
      notifyAddExistingMemberError: (ctx) =>
        toast.error(
          options.tt
            .looksLikeThisUserAlreadyHasAnExistingSubscriptionPleaseContactDdproDietdoctorComAndWellHelpYouTransferTheAccountToDdPro,
          {
            id: ctx.addingMemberNotification,
            duration: 15000,
          }
        ),
      notifyRemovingCurrentUser: assign({
        removingMemberNotification: (_) => toast.loading(options.tt.removing), // eslint-disable-line @typescript-eslint/no-unused-vars
      }),
      notifyCurrentUserRemoved: (ctx) =>
        toast?.success(options.tt.theMemberHasBeenRemoved, {
          id: ctx.removingMemberNotification,
        }),
      notifyRemoveError: (ctx) =>
        toast.error(options.tt.oopsCantPerformThisActionRightNow, {
          id: ctx.removingMemberNotification,
        }),
    },
    services: {
      loadGroup: () => client.query({ query: GetGroups }).then((res) => res.data),
      loadUsers: (ctx) =>
        client
          .query({
            query: GetUsers,
            variables: { input: { groupId: ctx.groupID } },
            fetchPolicy: "network-only",
          })
          .then((res) => res.data),
      addUser: (ctx, event) => {
        const { email, firstName, lastName, subscriptionTerm } = (event as SubmitEvent)
          .member as AddNewMemberFormValues;
        const payload: AddUserPayload = {
          groupId: ctx.groupID,
          firstName,
          lastName,
          email,
          subscriptionTerm,
        };
        return client.mutate({
          mutation: AddUser,
          variables: {
            input: payload,
          },
        });
      },
      removeUser: (ctx) =>
        client.mutate({
          mutation: RemoveUser,
          variables: { input: { id: (ctx.currentUser as GetUsers_getDDProMembers).id } },
        }),
    },
    guards: {
      isDDProAdmin: (_, event) => (event as DoneInvokeEvent<GroupIdPayload>).data.getDDProGroups.length > 0,
      isErrorAddingAnExistingUser: (_, event) =>
        /adding an existing user with an existing subscription is not allowed/.test(
          (event as DoneInvokeEvent<string>).data
        ),
    },
  });
};
