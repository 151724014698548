import { MembershipSubscriptionStatus } from "../../../types/graphql-global-types";
import { useDDProTranslations } from "../useDDProTranslations";

export const toLocalizedStatus = (
  tt: ReturnType<typeof useDDProTranslations>,
  status: MembershipSubscriptionStatus | "PENDING"
) => {
  switch (status) {
    case "PENDING":
      return tt.pending;
    case MembershipSubscriptionStatus.ACTIVE:
      return tt.active;
    case MembershipSubscriptionStatus.NEW:
      return tt.new;
    case MembershipSubscriptionStatus.CANCELLED:
      return tt.cancelled;
    case MembershipSubscriptionStatus.EXPIRED:
      return tt.expired;
    case MembershipSubscriptionStatus.INVALID:
      return tt.invalid;
    default:
      return tt.unknown;
  }
};
