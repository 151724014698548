import { useSelector } from "@xstate/react";
import React, { useContext, useEffect } from "react";
import { RegisterForDDProPage } from "../../../pages";
import { isBrowser } from "../../../utils/ssr";
import { useNavigate } from "../../../components/Link/Link";
import { LoginContext } from "../../../components/LoginProvider/LoginProvider";
import { pageContext } from "../../../components/PageProvider/PageProvider";
import { SEOReactHelmet } from "../../../components/SEO/SEOReactHelmet";
import { ClinicNameWithQR } from "./ClinicNameWithQR";
import { DDProStateContext, DDProStateProvider } from "./DDProStateProvider";
import { InfoPage } from "./InfoPage";
import { Members } from "./states/Members";
import { QR } from "./states/QR";
import { ProMachineState } from "./types";

const DDPro = () => {
  if (!isBrowser()) return null;

  const { loggedIn, loading } = useContext(LoginContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!loggedIn && !loading) {
      navigate({ to: RegisterForDDProPage });
    }
  }, [loggedIn, loading, navigate]);

  return loggedIn ? (
    <DDProStateProvider>
      <Views />
    </DDProStateProvider>
  ) : null;
};

const infoSelector = (s: ProMachineState) => s.matches("info");
const qrSelector = (s: ProMachineState) => s.matches("qr");
const membersSelector = (s: ProMachineState) =>
  s.matches("members") || s.matches("add") || s.matches("addingMember");
const withQRSelector = (s: ProMachineState) => s.matches("members") || s.matches("info") || s.matches("add");

const Views = () => {
  const { locale } = useContext(pageContext);
  const pro = useContext(DDProStateContext);
  const isInfo = useSelector(pro.machine!, infoSelector);
  const isQR = useSelector(pro.machine!, qrSelector);
  const isMembers = useSelector(pro.machine!, membersSelector);
  const withQRBanner = useSelector(pro.machine!, withQRSelector);

  return (
    <div className="m-auto md:max-w-screen-xl ">
      <SEOReactHelmet title={`Diet Doctor - DD Pro`} lang={locale as string} />
      <div className="py-6">
        {isQR && <QR />}
        {!isQR && withQRBanner && <ClinicNameWithQR />}
        {isMembers && <Members />}
      </div>
      {isInfo && <InfoPage />}
    </div>
  );
};

// ts-prune-ignore-next
export default DDPro;
