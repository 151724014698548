import { useSelector } from "@xstate/react";
import React, { useContext } from "react";
import { DoctorsPage } from "../../../pages";
import { Link } from "../../../components/Link/Link";
import { DDProStateContext } from "./DDProStateProvider";
import { ProMachineState } from "./types";

const infoSelector = (state: ProMachineState) => state.matches("info");

export const InfoPage = () => {
  const pro = useContext(DDProStateContext);
  const isInfo = useSelector(pro.machine!, infoSelector);

  return isInfo ? (
    <div className="">
      <div className="p-6 sm:flex sm:pt-20 pt-10">
        <h1 className="text-4xl m-0 font-normal">About Diet Doctor Pro</h1>
      </div>
      <div className="p-6 grid sm:grid-cols-2 sm:gap-x-10">
        <div className="col-span-1">
          <p className="mb-6 text-xl">
            Welcome to DD Pro beta. With Diet Doctor Pro, you can help your members keep low carb simple. We
            provide the evidence-based information, practical guidance, and meal plans to complement your
            medical care.
          </p>
          <h3 className="mt-0 text-2xl">Manage your members - all in one place</h3>
          <p className="mb-6 text-xl">
            Quickly add and remove members at any time or use the search to locate members, view member details,
            and check start dates.
          </p>
          <h3 className="mt-0 text-2xl">Onboard your members</h3>
          <p className="mb-6 text-xl">
            Curious about what features we offer? Our video with an introduction will walk you through Diet
            Doctor’s member benefits and the resources we have available to help your clients succeed.
          </p>
          <br />
          <iframe
            className="flex r-0 l-0 mx-auto"
            src={`https://player.vimeo.com/video/500526487?color=ffffff&amp;title=0&amp;byline=0&amp;portrait=0&amp;dnt=1`}
            width="250"
            height="214"
            frameBorder="0"
            allowFullScreen
          ></iframe>
          <h3 className="mt-0 text-2xl">Patient resources</h3>
          <p className="mb-6 text-xl">
            Take advantage of our patient resources for helpful infographics tailored for beginners.
          </p>
          <h3 className="mt-0 text-2xl">Open document with links</h3>
          <p className="mb-6 text-xl">See our range of informative and practical guides on popular topics.</p>
        </div>
        <div className="col-span-1">
          <h3 className="mt-0 text-2xl">{"Support & feedback"}</h3>
          <p className="mb-6 text-xl">
            We look forward to working with you as we add more functionality for Diet Doctor Pro. Share any
            suggestions for how we can make your life easier!
          </p>
          <p className="mb-6 text-xl">
            In need of any technical assistance or questions regarding payments? Please contact our support team
            at
            <a className="hover:underline" href="mailto:ddpro-support@dietdoctor.com">
              {" "}
              ddpro-support@dietdoctor.com
            </a>
            .
          </p>
          <p className="mb-6 text-xl">
            Questions or suggestions regarding our content or DD Pro functionality? Please contact our Medical
            Director, Dr. Bret Scher at
            <a className="hover:underline" href="mailto:bret@dietdoctor.com">
              {" "}
              bret@dietdoctor.com
            </a>
            .
          </p>
          <h3 className="mt-0 text-2xl">Put yourself on the map!</h3>
          <p className="mb-6 text-xl">
            Help even more people in need find you. Make sure you are on Diet Doctor’s “Find a low carb doctor”
            map.
          </p>
          <p className="mb-6 text-xl">
            <Link to={DoctorsPage}>Learn more</Link>
          </p>
        </div>
      </div>
    </div>
  ) : null;
};
