import { useActor } from "@xstate/react";
import * as React from "react";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { MdMoreHoriz } from "@react-icons/all-files/md/MdMoreHoriz";

import { GetUsers_getDDProMembers } from "../../../../../types/GetUsers";
import { MembershipSubscriptionStatus } from "../../../../../types/graphql-global-types";
import { useDDProTranslations } from "../../../useDDProTranslations";
import { DDProStateContext } from "../../DDProStateProvider";
import { toLocalizedStatus } from "../../utils";
import { ActionMenu } from "./ActionMenu";

const getInitials = (email: string | null, fistName: string | null, lastName: string | null) => {
  const f = fistName ? fistName?.charAt(0) : email?.charAt(0) ?? "";
  const l = lastName ? lastName?.charAt(0) : email?.charAt(1) ?? "";
  return (f + l).toUpperCase();
};

const getStatusBg = (status: MembershipSubscriptionStatus | "PENDING") =>
  status === "ACTIVE" ? "bg-blue opacity-50" : status === "NEW" ? "bg-grey" : "bg-light-grey";

const getMemberNormalized = (member: GetUsers_getDDProMembers) => ({
  initials: getInitials(member.email, member.firstName, member.lastName),
  fullName: member.firstName ? `${member.firstName} ${member.lastName}` : "N/A",
  email: member.email,
  subscriptionStatus: member.subscriptionStatus,
});

export function MemberItem({ member }: { member: GetUsers_getDDProMembers }) {
  const tt = useDDProTranslations();
  const pro = useContext(DDProStateContext);
  const [, send] = useActor(pro.machine!);

  const normalized = useMemo(() => getMemberNormalized(member), [member]);
  const { fullName, initials, email, subscriptionStatus } = normalized;
  const status = useMemo(() => subscriptionStatus ?? "PENDING", [subscriptionStatus]);
  const statusBg = useMemo(() => getStatusBg(status), [status]);
  const statusStr = useMemo(() => toLocalizedStatus(tt, status).toUpperCase(), [status, tt]);
  const node = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState(false);
  const onClickOnMore = useCallback(
    (e: any) => {
      if (!open) {
        e.stopPropagation();
        setOpen(!open);
      }
    },
    [open]
  );
  const onClickOnItem = useCallback(() => send({ type: "VIEW_MEMBER_DETAILS", member }), [send, member]);

  const viewDetailsAction = {
    label: "View details",
    handler: () => {
      send({ type: "VIEW_MEMBER_DETAILS", member });
      setOpen(false);
    },
  };

  const actions = member.groupAdmin
    ? [viewDetailsAction]
    : [
        viewDetailsAction,
        {
          label: "Remove",
          handler: () => {
            send({ type: "REMOVE_MEMBER", member });
            setOpen(false);
          },
        },
      ];

  useEffect(() => {
    const handleClick = (e: any) => {
      if (node.current?.contains?.(e.target)) {
        return;
      }
      setOpen(false);
    };
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
  }, []);

  return (
    <li
      onClick={onClickOnItem}
      className="grid grid-cols-12 gap-4 p-4 items-center md:hover:bg-lighter-blue cursor-pointer"
    >
      <div className="col-span-2 md:col-span-1 flex justify-center items-center justify-self-start">
        <i
          className={`${statusBg} flex font-medium h-10 items-center justify-center leading-tight not-italic rounded-full text-base text-white w-10`}
        >
          {initials}
        </i>
      </div>
      <h3 className="hidden md:block md:col-span-4 m-0 text-base font-medium truncate" title={fullName ?? ""}>
        {fullName}
      </h3>
      <h4 className="hidden md:block md:col-span-5 m-0 text-base font-medium truncate" title={email ?? ""}>
        {email}
      </h4>
      <div className="col-span-8 md:hidden flex flex-col">
        <h3 className="m-0 text-base font-medium truncate" title={fullName ?? ""}>
          {fullName}
        </h3>
        <h4 className="m-0 text-base truncate" title={email ?? ""}>
          {email}
        </h4>
      </div>
      <span
        className={`font-medium h-6 hidden items-center justify-center md:col-span-1 md:flex m-0 p-1 rounded-full text-white text-xs select-none ${statusBg}`}
      >
        {statusStr}
      </span>
      <span className={`w-2 h-2 col-span-1 md:hidden m-0 rounded-full ${statusBg}`} />
      <span
        onClickCapture={onClickOnMore}
        ref={node}
        className="col-span-1 md:col-span-1 flex justify-center items-center relative justify-self-end"
      >
        <MdMoreHoriz className="cursor-pointer" />
        {open && <ActionMenu actions={actions} />}
      </span>
    </li>
  );
}
