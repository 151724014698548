import { useActor, useSelector } from "@xstate/react";
import * as React from "react";
import { useCallback, useContext, useMemo } from "react";
import { pageContext } from "../../../../components/PageProvider/PageProvider";
import { useDDProTranslations } from "../../useDDProTranslations";
import { BackButton } from "../assets/BackButton";
import { DDProStateContext } from "../DDProStateProvider";
import { ProMachineState } from "../types";
import { Code } from "./qr/Code";
import { joinGroupToUrl } from "./qr/utils";

const groupIDSelector = (state: ProMachineState) => state.context.groupID;
const groupTitleSelector = (state: ProMachineState) => state.context.groupTitle;

export function QR() {
  const { locale } = useContext(pageContext);
  const pro = useContext(DDProStateContext);
  const [, send] = useActor(pro.machine!);
  const groupID = useSelector(pro.machine!, groupIDSelector);
  const groupTitle = useSelector(pro.machine!, groupTitleSelector);
  const url = useMemo(
    () => joinGroupToUrl(locale, document.location.origin, groupID, groupTitle),
    [groupID, groupTitle, locale]
  );
  const tt = useDDProTranslations();
  const handleBack = useCallback(() => send("CLOSE"), [send]);

  return (
    <div className="w-full md:w-1/2 mx-auto px-4 md:px-0">
      <div className="-ml-2 mb-2 mt-6">
        <BackButton onClick={handleBack} />
      </div>
      <div className="flex flex-col items-center mb-8">
        <div className="p-12 bg-lighter-grey rounded-full">
          <Code url={url} />
        </div>
      </div>
      <h1
        className="font-normal text-4xl m-0 mb-4"
        dangerouslySetInnerHTML={{ __html: tt.welcomeToDietDoctor }}
      />
      {/* @ts-ignore */}
      <p className="text-lg">{tt.pleaseScanTheQrCodeToSignUpForDietDoctorViaGroupTitleHere}</p>
      <p className="text-lg">
        {tt.youllGetAccessToPersonalizedMealPlansMemberProgramsAndSoMuchMoreAHealthierLifeStartsNow}
      </p>
    </div>
  );
}
