import { useActor, useSelector } from "@xstate/react";
import { Field, Form, Formik } from "formik";
import * as React from "react";
import { useContext, useCallback } from "react";
import * as yup from "yup";
import { ActionButton, ActionButtonAlt } from "../../form/ActionButton";
import { EmailInput } from "../../form/EmailInput";
import { FirstNameInput } from "../../form/FirstNameInput";
import { LastNameInput } from "../../form/LastNameInput";
import { SubscriptionTermSelect } from "../../form/SubscriptionTermSelect";
import { useDDProTranslations } from "../../useDDProTranslations";
import { DDProStateContext } from "../DDProStateProvider";
import { ProMachineState, SubscriptionPayer } from "../types";

const isAddingSelector = (s: ProMachineState) => s.matches("addingMember");
const isAdminPaysSelector = (s: ProMachineState) => s.context.subscriptionPayer === SubscriptionPayer.ADMIN;

const validationSchema = (tt: ReturnType<typeof useDDProTranslations>) =>
  yup.object({
    firstName: yup.string(),
    lastName: yup.string(),
    email: yup.string().email(tt.enterAValidEmail).required(tt.emailIsRequired),
  });

export function NewMember() {
  const pro = useContext(DDProStateContext);
  const [, send] = useActor(pro.machine!);
  const isAdding = useSelector(pro.machine!, isAddingSelector);
  const isAdminPays = useSelector(pro.machine!, isAdminPaysSelector);
  const handleCancel = useCallback(() => send("CANCEL"), [send]);
  const tt = useDDProTranslations();
  return (
    <div className="w-full md:w-1/2 mx-auto px-4 md:px-0">
      <div className="mb-4 mt-8">
        <h1 className="font-normal text-4xl m-0 mb-4">{tt.newMember}</h1>
      </div>
      <Formik
        initialValues={{ email: "", firstName: "", lastName: "" }}
        validationSchema={validationSchema(tt)}
        onSubmit={(values) => {
          const member = {
            ...values,
          };
          send({ type: "SUBMIT", member });
        }}
      >
        {({ dirty, errors }) => (
          <Form>
            <Field type="text" name="firstName" optional="true" component={FirstNameInput} />
            <Field type="text" name="lastName" optional="true" component={LastNameInput} />
            <Field type="email" name="email" component={EmailInput} />
            {isAdminPays && (
              <Field
                as="select"
                name="subscriptionTerm"
                component={SubscriptionTermSelect}
                title={tt.subscription}
              />
            )}
            <div className="flex flex-col mt-4 md:flex-row-reverse">
              <ActionButton
                type="submit"
                label={tt.submit}
                busy={isAdding}
                disabled={typeof errors.email === "string" || !dirty}
              />
              <ActionButtonAlt label={tt.cancel} onClick={handleCancel} />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export interface AddNewMemberFormValues {
  firstName: string;
  lastName: string;
  email: string;
  subscriptionTerm: string;
}
