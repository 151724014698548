import { useActor, useSelector } from "@xstate/react";
import format from "date-fns/fp/format";
import parseISO from "date-fns/fp/parseISO";
import * as React from "react";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { GetUsers_getDDProMembers } from "../../../../types/GetUsers";
import { useDDProTranslations } from "../../useDDProTranslations";
import { BackButton } from "../assets/BackButton";
import { DDProStateContext } from "../DDProStateProvider";
import { ProMachineState } from "../types";
import { toLocalizedStatus } from "../utils";

const userSelector = (s: ProMachineState) => s.context.currentUser as GetUsers_getDDProMembers;

const getMemberNormalized = (member: GetUsers_getDDProMembers) => ({
  id: member.id,
  fullName: member.firstName ? `${member.firstName} ${member.lastName}` : "N/A",
  email: member.email,
  endDate: member.subscriptionExpireTime ? format("yyyy-MM-dd", parseISO(member.subscriptionExpireTime)) : null,
  subscriptionStatus: member.subscriptionStatus,
  startDate: format("yyyy-MM-dd", parseISO(member.createTime)),
});

export function ViewDetails() {
  const tt = useDDProTranslations();
  const pro = useContext(DDProStateContext);
  const [, send] = useActor(pro.machine!);
  const user = useSelector(pro.machine!, userSelector);
  const { id, fullName, email, startDate, endDate, subscriptionStatus } = useMemo(
    () => getMemberNormalized(user),
    [user]
  );
  const status = useMemo(() => subscriptionStatus ?? "PENDING", [subscriptionStatus]);
  const statusStr = useMemo(() => toLocalizedStatus(tt, status), [status, tt]);
  const handleBack = useCallback(() => send("CLOSE"), [send]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="w-full md:w-1/2 mx-auto px-4 md:px-0">
      <div className="-ml-2 mb-2 mt-6">
        <BackButton onClick={handleBack} />
      </div>
      <h1 className="font-normal text-4xl m-0 mb-4">{fullName}</h1>
      <dl>
        <dt className="font-medium py-2 text-grey text-base">{tt.email}</dt>
        <dd className="mb-4 font-medium">{email}</dd>
        <dt className="font-medium py-2 text-grey text-base">{tt.startDate}</dt>
        <dd className="mb-4 font-medium">{startDate ?? "-"}</dd>
        <dt className="font-medium py-2 text-grey text-base">{tt.endDate}</dt>
        <dd className="mb-4 font-medium">{endDate ?? "-"}</dd>
        <dt className="font-medium py-2 text-grey text-base">{tt.subscriptionStatus}</dt>
        <dd className="mb-4 font-medium">{statusStr}</dd>
        <dt className="font-medium py-2 text-grey text-base">{tt.id}</dt>
        <dd className="mb-4 font-medium">{id}</dd>
      </dl>
      {/* <pre className='text-sm'>{JSON.stringify(user, null, 2)}</pre> */}
    </div>
  );
}
