import { useInterpret } from "@xstate/react";
import React, { createContext, useMemo } from "react";
import { InterpreterFrom, StateMachine } from "xstate";
import { useNavigate } from "../../../components/Link/Link";
import { useDDProTranslations } from "../useDDProTranslations";
import { machine } from "./machine";
import { ProMachineContext, ProMachineEvents } from "./types";

export const DDProStateContext = createContext<{
  machine?: InterpreterFrom<
    StateMachine<ProMachineContext, any, ProMachineEvents, { value: any; context: ProMachineContext }>
  >;
}>({});

export const DDProStateProvider: React.FunctionComponent<{ children?: React.ReactNode }> = (props) => {
  const firstState = useMemo(
    () =>
      document.location.pathname.search("/pro/about") !== -1
        ? "info"
        : document.location.pathname.search("/pro/add") !== -1
        ? "add"
        : "members",
    []
  );

  const tt = useDDProTranslations();
  const navigate = useNavigate();
  const stateMachine = useMemo(() => machine({ navigate, firstState, tt }), [firstState, navigate, tt]);
  const i = useInterpret(stateMachine);

  i.start();

  return <DDProStateContext.Provider value={{ machine: i }}>{props.children}</DDProStateContext.Provider>;
};
